import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
// import NotFoundComponent from '../views/NotFoundComponent.vue'

const routes = [
  // {
  //   path: '/404',
  //   name: 'not-found',
  //   component: NotFoundComponent
  // },
  // {
  //   path: '/:pathMatch(.*)*',
  //   redirect: '/404'
  // },
  {
    path: '/',
    name: 'portfolio',
    component: HomeView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
